function overwriteBaiduUID(_hmt) {
    _hmt.push(['_setUserId', global.trackId]);
    _hmt.push(["_setUserProperty", { "visitorId": global.trackId }]);
}

function enableBaiduAnalytics(option, loadJs) {
    global._hmt = global._hmt || [];
    overwriteBaiduUID(global._hmt); //封装核心目的
    if (option) {
        var z = option.ready;
        if (z && typeof(z) == 'function') {
            z.call(null, global._hmt);
        }
    }
    //将_htm的配置和加载hmt的js分开，这样即使不加载hmt的js时候调用_hmt也不会报未定义
    if (loadJs) {
        //转化事件代理对象
        //因为百度转化事件的对象不能是ajax元素，因此先将一个元素添加至DOM，
        //这样在弹窗代码重就可以通过它传递转化事件。
        //create_hmt_conversion_proxy_element();
        (function() {
            var hm = document.createElement("script");
            hm.src = option.src;
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    }
}

// function fireBaiduConversionEvent(event) {
//     if (event) {
//         var proxy_element = document.getElementsByClassName("hmt_conversion_proxy_element")[0];
//         if (proxy_element) {
//             var eventObj = document.createEvent("MouseEvents");
//             eventObj.initMouseEvent(event.type, true, true, window, 1, event.screenX, event.screenY, event.clientX, event.clientY, false, false, false, false, 0, null);
//             proxy_element.dispatchEvent(eventObj);
//         }
//     }
// }

// var appendHtml = function(el, html) {
//     var divTemp = document.createElement("div");
//     var fragment = document.createDocumentFragment();
//     divTemp.innerHTML = html;
//     var nodes = divTemp.childNodes;
//     for (var i = 0, length = nodes.length; i < length; i += 1) {
//         fragment.appendChild(nodes[i].cloneNode(true));
//     }
//     el.appendChild(fragment);
// }

// function create_hmt_conversion_proxy_element() {
//     appendHtml(document.body, `<a href="javascript:void(0)" class="hmt_conversion_proxy_element" style="height:0px;">hmt_conversion_proxy_element</a>`);
//     var proxy_element = document.getElementsByClassName("hmt_conversion_proxy_element")[0];
//     if (proxy_element) {
//         proxy_element.addEventListener("click", function(e) {
//             e.target.setAttribute("id", "open_chat_proxy");
//         });
//     }
// }

export default {
    enableBaiduAnalytics: enableBaiduAnalytics,
    //fireBaiduConversionEvent: fireBaiduConversionEvent,
}