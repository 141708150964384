class sdkOption {
    constructor(logger) {
        var ee = x => logger.infofn.call(logger,x);
        this.s = "//track.meierbei.com/";
        this.is_mobile = /mobile/i.test(navigator.userAgent.toLowerCase());
        this.debug = 0;
        this.origin = location.protocol + '//' + location.host;
        this.keyword = null;

        //chat open/close hook
        this.onbeforeopen = x => ee('onbeforeopen');

        //conf event
        this.onconfigure = x => ee('onconfigure');
        this.oninitialized = x => ee('oninitialized');

        //dialog api event
        this.onchatsdkload = x => ee('onchatsdkload');
        this.onbeforeshow = x => ee('onbeforeshow');
        this.onbeforehide = x => ee('onbeforehide');

        //dialog theme load event
        this.onload = x => ee('onload');
        this.oncssload = x => ee('oncssload');
        this.onhtmlload = x => ee('onhtmlload');
        this.onjsload = x => ee('onjsload');
        this.openChat = x => ee('openChat');
        this.hide = x => ee('hide');
        this.show = x => ee('show');
        this.funcNames = ["onbeforeopen",
            "onconfigure",
            "oninitialized",
            "onchatsdkload",
            "onbeforeshow",
            "onbeforehide",
            "onload",
            "oncssload",
            "onhtmlload",
            "onjsload",
            "openChat",
            "hide",
            "show"
        ]
    }
}

export default sdkOption;
