import chatSdkBase from "./chatSdkBase";

export default class mebChatSdk extends chatSdkBase {
    constructor(sdkWrapper) {
        super(sdkWrapper);
    }
    sdkOpenChatInNewTab() {
        CVD_SDK.openWindowInNewTab();
    }
    sdkOpenChatOnPage(params) {
        CVD_SDK.openWindow();
    }
    sdkGetOpenUrl(chat_url, params) {
        var url = chat_url;
        var thirdpart_params = [];
        for (let i in params) {
            thirdpart_params.push({ key: i, label: this.wrapper.trimStr(params[i], 500) });
        }
        url = this.wrapper.urlPrependParameter(url, 'thirdpart_params', encodeURIComponent(JSON.stringify(thirdpart_params)));
        return url;
    }
    sdkOpenChat(chat_url, params) {
        var query = [];
        for (var x in params) {
            query.push({ key: x, label: params[x].toString() });
        }
        CVD_SDK.addExtraQuerys({ thirdpart_params: query });
        super.sdkOpenChat(chat_url, params);
    }
}