class ThemePack {
    constructor(dialog) {

        if (!dialog || !dialog.options) {
            throw new Error('missing dialog SDK.');
        }
        var mHide = 'remove';
        var mShow = 'add';
        var types = ["TOP", "BOTTOM", "CENTER", "ASIDE"];

        function targetType(target) {
            var id = target.id;
            var upsearch = 3;
            while ((!id || id.indexOf('DIALOG') == -1) && upsearch > 0) {
                upsearch--;
                target = target.parentElement;
                id = target.id;
            }
            if (id) {
                if (id.indexOf('DIALOG_TOP') != -1)
                    return 'TOP';
                if (id.indexOf('DIALOG_BOTTOM') != -1)
                    return 'BOTTOM';
                if (id.indexOf('DIALOG_CENTER') != -1)
                    return 'CENTER';
                if (id.indexOf('DIALOG_ASIDE') != -1)
                    return 'ASIDE';
            }
            return 'NONE';
        }

        function themePackOp(type, m) {
            type = type.toUpperCase();
            if (type == 'ALL') {
                var val = 1;
                types.map(function(v) { val = val && themePackOp(v, m) });
                return val;
            } else {
                var cls = 'DIALOG_' + type + '_SHOW';
                for (var i = 0; i < types.length; i++) {
                    if (types[i] == type) {
                        document.body && document.body.classList[m](cls);
                        return 1;
                    }
                }
            }
        }

        function changeInterval(type) {
            var n = 'DIALOG_' + type.toUpperCase() + '_TIMELINE';
            var timeline = window[n];
            if (typeof timeline == "object" && timeline.length > 0) {
                dialog.conf.showInterval[type] = timeline.shift();
            }
        }

        this.showUp = function(d) { themePackOp((d || {}).where || 'none', mShow); };
        this.hide = function(d) {
            var e = window.event || dialog.getEvent(dialog.options.hide);
            var isJsLoad = e && e.type == 'load' && e.currentTarget.tagName == 'SCRIPT';
            var isElementEvent = e && e.type == 'click';
            if (isJsLoad) {
                themePackOp('ALL', mHide) && changeInterval('ALL');
            } else if (isElementEvent) {
                var target = e.currentTarget;
                var type = targetType(target);
                themePackOp(type, mHide) && changeInterval(type);
            } else if (d && d.where) {
                themePackOp(d.where, mHide) && changeInterval(d.where);
            }

            if (e && e.stopPropagation) {
                e.cancelBubble = true;
                e.stopPropagation();
            }
        };
        this.targetType = targetType;
        //加载完成时重置状态为全部隐藏
        //此后，dialog框架根据showDelay和showInterval来处理
        themePackOp('ALL', mHide);
    }
}

export default ThemePack;