class logger {
    constructor() {
        this.enabled = true;
        this.prefix = "";
    }
    infofn(funcName) {
        if (!this.enabled)
            return;
        if (this.prefix && this.prefix.length > 0) {
            console.log(`${this.prefix} ${funcName} called`);
        } else {
            console.log(`${funcName} called`);
        }
    }
    info(msg) {
        if (!this.enabled)
            return;
        this.infoAways(msg);
    }
    infoAways(msg) {
        if (this.prefix.length > 0) {
            console.log(`${this.prefix} ${msg}`);
        } else {
            console.log(`${msg}`);
        }
    }
}
export default logger;
