var sa_prod_endpoint = 'https://sensorsdata.meb.com:4006/sa?project=production&token=DUxYZNGC08HUIlTtX4aSyhyvtRmft1mS';
var sa_test_endpoint = 'https://sensorsdata.meb.com:4006/sa?project=default&token=cz8Ih7weyScXJbidoahEvKUDBy6IlBHG';
var overwriteSensorsUUID = function() {
    if (window.sensors) {
        var sensors_uid = window.sensors.quick('getAnonymousID');
        if (sensors_uid != window.trackId) {
            window.sensors.identify(window.trackId, true);
        }
    }
}
var enableSensorsTrack = function(options, loadJs) {
    var sa_endpoint = options.env === "prod" ? sa_prod_endpoint : sa_test_endpoint;
    (function(para) {
        var p = para.sdk_url,
            n = para.name,
            w = window,
            d = document,
            s = 'script',
            x = null,
            y = null,
            z = para.ready;
        if (typeof(w['sensorsDataAnalytic201505']) !== 'undefined') {
            return false;
        }
        w['sensorsDataAnalytic201505'] = n;
        w[n] = w[n] || function(a) {
            return function() {
                (w[n]._q = w[n]._q || []).push([a, arguments]);
            }
        };
        var ifs = ['track', 'quick', 'register', 'registerPage', 'registerOnce', 'trackSignup', 'trackAbtest', 'setProfile', 'setOnceProfile', 'appendProfile', 'incrementProfile', 'deleteProfile', 'unsetProfile', 'identify', 'login', 'logout', 'trackLink', 'clearAllRegister', 'getAppStatus'];
        for (var i = 0; i < ifs.length; i++) {
            w[n][ifs[i]] = w[n].call(null, ifs[i]);
        }
        overwriteSensorsUUID(); //封装核心目的
        if (z && typeof(z) == 'function') {
            z.call(null, w[n]);
        }
        //将配置项和js载入分开，这样即使调用方使用sensors对象时不会报未定义
        if (loadJs && !w[n]._t) {
            x = d.createElement(s);
            y = d.getElementsByTagName(s)[0];
            x.async = 1;
            x.src = p;
            x.setAttribute('charset', 'UTF-8');
            w[n].para = para;
            y.parentNode.insertBefore(x, y);
        }
    })(Object.assign({
        sdk_url: 'https://static.sensorsdata.cn/sdk/1.14.6/sensorsdata.min.js',
        heatmap_url: 'https://static.sensorsdata.cn/sdk/1.14.6/heatmap.min.js',
        name: 'sensors',
        show_log: true,
        send_type: 'image',
        server_url: sa_endpoint,
        cross_subdomain: false,
        heatmap: {}
    }, options));
};

export default {
    enableSensorsTrack: enableSensorsTrack,
    updateOption: function(k, v) {
        if (global.sensors && global.sensors[k])
            global.sensors[k] = v;
    },
    track: function(n, args) {
        global.sensors && global.sensors.track(n, args);
    }
}