import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import baiduIntergration from './baiduIntergration';
import identity from './uniid';
import sensorsIntergration from './sensorsIntergration';
import ThemePack from "./themePack";
import sdkOption from "./sdkOption";
import logger from "./logger";
import { mebChatSdk, xiaoduoChatSdk } from "./providers/chatSdkProvider"
//extensions
Date.prototype.format = function (format) {
    var apd = function (i, v) {
        var str = (i + 100).toString();
        return str.substr(1, v == null ? 2 : Math.min(v, str.length - 1));
    };
    var d = {};
    d['yyyy'] = this.getFullYear();
    d['MM'] = apd(this.getMonth() + 1);
    d['dd'] = apd(this.getDate());
    d['HH'] = apd(this.getHours());
    d['mm'] = apd(this.getMinutes());
    d['ss'] = apd(this.getSeconds());
    for (var x in d) {
        format = format.replace(x, d[x]);
    }
    return format;
};
Array.prototype.indexOf = Array.prototype.indexOf || function (val) {
    var value = this;
    for (var i = 0; i < value.length; i++) {
        if (value[i] == val) return i;
    }
    return -1;
};
//core
class md {
    constructor(dialog_opt) {
        this.logger = new logger();
        this.logger.prefix = '[meb_dialog]';
        this.logger.enabled = dialog_opt.debug;
        this.options = new sdkOption(this.logger);
        Object.assign(this.options, dialog_opt);
        this.sensorsTrackEnabled = false;
        this.baiduAnalyticsEnabled = false;
        this.version = '20210923';
        this.PAGE_ENTRY_TS = new Date().getTime();
        this.conf = {};

        this.s_base = "";
        this.showOption = { where: 'ALL', by: 'user' };
        this.chatSdkLoad = 0;
        this.themeLoad = 0;
        this.crm_params = {
            src_url: '',
            ref_url: '',
            s_code: ''
        };

        // dialog theme settings
        this.s_base = 'https:' + this.options.s;
        // this.s_base = this.options.s;
        this.path_conf = this.s_base + "conf";
        this.path_linkchat = this.s_base + "linkchat";
        this.path_dept = this.s_base + "selectDeptByScode";
        this.b_channel = this.options.is_mobile ? "mobile" : "www";

        //crm link params
        this.crm_params.src_url = location.href.replace(/\/*$/, '');
        this.crm_params.ref_url = document.referrer.substring(0, 500);

        if (this.crm_params.src_url[this.crm_params.src_url.length - 1] == '#') {
            this.crm_params.src_url = this.crm_params.src_url.slice(0, this.crm_params.src_url.length - 1);
        }

        //scode
        this.crm_params.s_code = this.urlParam(location.href, 'scode');
        if (this.crm_params.s_code && this.crm_params.s_code[this.crm_params.s_code.length - 1] == '#') {
            this.crm_params.s_code = this.crm_params.s_code.slice(0, this.crm_params.s_code.length - 1);
        }
        if (this.crm_params.s_code) {
            identity.setCookie('scode', this.crm_params.s_code, 365);
        } else {
            this.crm_params.s_code = identity.readCookie('scode');
            if (!this.crm_params.s_code) {
                this.crm_params.s_code = window.location.host;
            } else if (this.crm_params.s_code.startsWith('{')) { //兼容官网
                try {
                    var p = JSON.parse(this.crm_params.s_code);
                    if (p.Content && p.Content.Scod) {
                        this.crm_params.s_code = p.Content.Scod;
                    }
                } catch (err) {
                    this.conf.debug && console.log("WARN: decode scode failed. " + err.message);
                }
            }
        }
        window.openChat = this.exportFunc('openChat');
        window.hideDialog = this.exportFunc('hide');
        window.showDialog = this.exportFunc('show');

        //兼容旧版
        window.openMessage = window.openChat;

        if (!this.options.keyword) {
            this.options.keyword = this.getKeywords();
        }
        if (!window.trackId) {
            this.logger.info("ERROR: trackId is missing!!!");
            return;
        }

        //download dialog conf
        this.downloadConfig(this.configDownloadSuccess);
    }

    configDownloadSuccess(d) {
        if (!d || d.length === 0) {
            this.logger.infoAways("ERROR: conf load failure. invalid response.");
            return;
        }
        this.conf = this.extend(this.conf, JSON.parse(d));

        if (this.conf.error) {
            this.logger.infoAways('ERROR: ' + this.conf.error);
            return;
        }

        if (this.conf.debug) {
            this.options.debug = this.conf.debug; //force enable if admin wants.
            this.logger.enabled = this.conf.debug;
            this.logger.infoAways('version ' + this.version);
        }
        sensorsIntergration.updateOption("show_log", this.options.debug);
        if (!window.trackId) {
            this.logger.info("ERROR: trackId is invalid!!!");
            return;
        }
        if (this.conf.sdkLoadType == undefined) {
            this.conf.sdkLoadType = 0;
        }
        this.configChatSDK();
        this.options.onconfigure.call(this);

        if (this.conf.abTestCode) {
            identity.setCookie("abTestCode", this.conf.abTestCode, 365);
        } else {
            identity.setCookie("abTestCode", "", -1);
        }
        if (this.conf.abTestMark) {
            identity.setCookie("abTestMark", this.conf.abTestMark, 365);
        } else {
            identity.setCookie("abTestMark", "", -1);
        }
        if (!this.options.disableChatSdk && this.conf.sdkLoadType != -1) {
            switch (this.conf.sdkLoadType) {
                case 2:
                    document.write(unescape("%3Cscript type='text/javascript' src='" + this.conf.pvd_js + "' onload='window.meb_dialog&&meb_dialog.onChatSdkLoad' charset='utf-8'%3E%3C/script%3E"));
                    document.close();
                    break;
                case 1:
                    //asyncLoad
                    this.loadJs(this.conf.pvd_js, true, this.onChatSdkLoad);
                    break;
                case 0:
                default:
                    //syncLoad
                    this.loadJs(this.conf.pvd_js, false, this.onChatSdkLoad);
            }

            if (!this.conf.dialog_theme_resource) {
                var f = this.conf.theme_server + "dialog/" + this.b_channel + "_" + this.conf.dialog_theme;
                this.conf.dialog_theme_resource = {
                    ui: f + ".html",
                    js: f + ".js",
                    css: f + ".css"
                };
            }

            if (!this.conf.dialog_theme_hidden) {
                this.loadTheme();
            }
        }
        this.options.oninitialized.call(this);
    }

    onChatSdkLoad() {
        this.chatSdkLoad = 1;
        this.options.onchatsdkload();
    }
    configChatSDK() {
        if (this.conf.pvd_name == 'xd') {
            window.CVD_SDK_CONFIG = { debugMode: this.options.debug, hideEntryBtn: true };
            window.onCvdSdkReady = function (dlg) {
                return function () {
                    window.CVD_SDK && window.CVD_SDK.setDebugMode(false);
                };
            }(this);
        }
    }
    exportFunc(funcName) {
        return function () {
            var that = this;
            return function (p) {
                that[funcName](p);
            }
        }.call(this);
    }
    downloadConfig(callback) {
        var path = location.pathname;
        if (path.length > 500)
            path = path.substr(0, 500);
        var d = {
            sgk: (this.options.SGK || { SGK: 0 }).SGK,
            ksgk: (this.options.KSGK || { SGK: 0 }).SGK,
            mobile: this.options.is_mobile ? true : false,
            kw: this.options.keyword,
            path: path,
            sourceurl: this.crm_params.src_url,
            scode: this.crm_params.s_code,
            referer: this.crm_params.ref_url,
            isFirstVisit: global.isFirstVisit,
            abTestCode: identity.readCookie("abTestCode"),
            abTestMark: identity.readCookie("abTestMark"),
        };
        var url = this.path_conf + "?origin=" + encodeURIComponent(this.options.origin);
        this.doRequest(url, d, 'POST', "application/json", true, callback);
    }
    getParamData() {
        this.crm_params.src_url = this.trimStr(this.crm_params.src_url, 500);
        this.crm_params.ref_url = this.trimStr(this.crm_params.ref_url, 500);
        var d = {};
        d.fromClientId = window.trackId;
        d.scode = this.crm_params.s_code;
        d.sourceurl = this.crm_params.src_url;
        d.refererurl = this.crm_params.ref_url;
        d.v = this.version; //dialog loader version
        if (this.conf.pvd_name == 'kst') {
            d.type = this.conf.pvd_name;
            d.searchurl = this.crm_params.ref_url;
            d.t = this.time();
        }
        if (this.conf.kws && this.conf.kws.length > 0) {
            //kw.type, 1=AREA,2=HOSPITAL,3=DOCTOR,4=PROJECT
            //kw.groups, admin defined groups the kw belongs to
            //kw.group, the group that selected by a admin defined ratio if the kw belongs to many groups
            for (var i = 0; i < this.conf.kws.length; i++) {
                var kw = this.conf.kws[i];
                if (kw.group && (d.vip = kw.group.name)) {
                    break;
                }
            }
        }
        if (this.conf.SGK) {
            d.exp = this.conf.SGK;
        }
        return d;
    }
    time() {
        return new Date().format('MM.dd.HH.mm');
    }
    dateTime() {
        return new Date().format('yyyy-MM-dd HH:mm:ss');
    }
    date() {
        return new Date().format('yyyy-MM-dd');
    }
    trimStr(str, len) {
        if (typeof str == 'string' && str.length > len) {
            str = str.substr(0, len - 3) + '...';
        }
        return str.toString();
    }
    loadTheme() {
        var that = this;
        this.loadCss();
        this.doRequest(this.conf.dialog_theme_resource.ui, { _: encodeURIComponent(this.options.origin) }, "GET", null, true, function (html) {
            this.html = html;
            that.handleHtmlLoad();
        });
    }
    onThemeLoad() {
        this.themeLoad = 1;

        //control mode
        if (this.conf.showMode == undefined) {
            this.conf.showMode = 1;
        }
        //theme pack or its part FIRST show delay
        if (this.conf.showDelay == undefined) {
            this.conf.showDelay = {
                ALL: 0,
                TOP: 0,
                ASIDE: 0,
                CENTER: 0,
                BOTTOM: 0
            };
        }
        //theme pack or its part show interval
        if (this.conf.showInterval == undefined) {
            this.conf.showInterval = {
                ALL: [10, 15, 30, 50],
                TOP: [10, 15, 30, 50],
                ASIDE: [10, 15, 30, 50],
                CENTER: [10, 15, 30, 50],
                BOTTOM: [10, 15, 30, 50]
            };
        }
        var sd = this.conf.showDelay; //new user only.
        for (var w in sd) {
            identity.readCookie('DIALOG_' + w + '_SHOW_TS') && (sd[w] = 0);
        }
        if (this.conf.showMode == 0) {
            setTimeout(showDialog, this.conf.showDelay["ALL"] * 1000, this.extend({ by: 'timer' }, this.showOption));
        } else {
            for (var w2 in sd) {
                w2 != 'ALL' && setTimeout(showDialog, sd[w2] * 1000, this.extend({ by: 'timer', where: w2 }, this.showOption));
            }
        }
        this.options.onjsload.call(this);
        this.options.onload.call(this);
    }
    handleHtmlLoad() {
        if (document.body == null) {
            var h = this.exportFunc("handleHtmlLoad");
            setTimeout(h, 200, this);
        } else {
            this.appendHTML(document.body, this.html);
            this.options.onhtmlload.call(this);

            //create themePack and bind events
            //note the binding may be overwritten by the theme js.
            this.themePack = new ThemePack(this);

            this.options.show = x => this.themePack.showUp(x);
            this.options.hide = x => this.themePack.hide(x);

            if (this.conf.doNotLoadThemeJs) {
                this.onThemeLoad();
            } else {
                var url = this.conf.dialog_theme_resource.js + "?_=" + encodeURIComponent(this.options.origin);
                this.loadJs(url, true, this.onThemeLoad);
            }
        }
    }
    loadCss() {
        var that = this;
        var link = document.createElement('link');
        link.async = true;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = this.conf.dialog_theme_resource.css + "?_=" + encodeURIComponent(this.options.origin);
        link.onload = function () { that.options.oncssload.call(that); };
        document.getElementsByTagName("head")[0].appendChild(link);
    }
    loadJs(url, async, callback) {
        var that = this;
        var bp = document.createElement('script');
        bp.src = url;
        bp.async = async;
        bp.onload = function (e) {
            callback && callback.call(that);
        };
        var s = document.getElementsByTagName("script")[0];
        s.setAttribute("charset", "UTF-8")
        s.parentNode.insertBefore(bp, s);
    }

    extend(target, source, overwrite) {
        for (var p in source) {
            if (source.hasOwnProperty(p)) {
                if (!target.hasOwnProperty(p)) {
                    target[p] = source[p];
                }
                if (!!overwrite) {
                    target[p] = source[p];
                }
            }
        }
        return target;
    }
    doRequest(url, data, method, content_type, async, callback) {
        method = (method === "GET" ? "GET" : "POST");
        var xhr = window.ActiveXObject ? (typeof XDomainRequest != "undefined" ? new XDomainRequest() : new ActiveXObject("Microsoft.XMLHTTP")) : new XMLHttpRequest();
        xhr.userToken = this;
        if (method == "GET") {
            for (var key in data) {
                url += ("&" + key + "=" + (data[key] != null ? encodeURIComponent(data[key]) : ""));
            }
            if (url.indexOf('?') == -1)
                url = url.replace('&', '?');
            xhr.open(method, url, async);
        } else {
            xhr.open(method, url, async);
        }
        if (content_type && 'setRequestHeader' in xhr) {
            xhr.setRequestHeader("Content-type", content_type);
        } else {
            xhr.setRequestHeader("Content-type", 'text/plain'); //ie XDomainRequest support only
        }
        if ('withCredentials' in xhr) {
            if (url.indexOf('.css') != -1 || url.indexOf('.js') != -1 || url.indexOf('.html') != -1) {
                xhr.withCredentials = false;
            } else {
                xhr.withCredentials = true;
            }
        }
        xhr.onload = function (e) {
            if (typeof XDomainRequest != "undefined" && callback) {
                callback.call(that, xhr.responseText);
            }
        };
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && (xhr.status >= 200 || xhr.status < 400)) {
                callback && callback.call(xhr.userToken, xhr.responseText); //raw for html/js/css
            } else if (xhr.readyState == 4) {
                throw new Error(`unexpected status code: ${xhr.status}`)
            }
        };
        if (method == "GET") {
            xhr.send();
        } else {
            xhr.send(JSON.stringify(data));
        }
    }
    appendHTML(el, html) {
        var divTemp = document.createElement("div");
        var fragment = document.createDocumentFragment();
        divTemp.innerHTML = html;
        var nodes = divTemp.childNodes;
        for (var i = 0, length = nodes.length; i < length; i += 1) {
            fragment.appendChild(nodes[i].cloneNode(true));
        }
        el.appendChild(fragment);
    }
    urlPrependParameter(url, name, value) {
        var idx = url.indexOf('?');
        if (idx != -1) {
            return url.substring(0, idx + 1) + name + "=" + value + "&" + url.substring(idx + 1);
        } else {
            return url + "?" + name + "=" + value;
        }
    }
    getDomainQuery(url) {
        var d = [];
        var st = url.indexOf('//', 1);
        var _domain = url.substring(st + 1, url.length);
        var et = _domain.indexOf('/', 1);
        d.push(_domain.substring(1, et));
        d.push(_domain.substring(et + 1, url.length));
        return d
    }
    getKeywords() {
        var eg = [];
        eg.push(['baidu', 'wd']);
        eg.push(['google', 'q']);
        eg.push(['soso', 'w']);
        eg.push(['yodao', 'q']);
        eg.push(['bing', 'q']);
        eg.push(['yahoo', 'q']);
        eg.push(['sogou', 'query']);
        eg.push(['gougou', 'search']);
        eg.push(['360', 'q']);
        eg.push(['sm', 'q']);
        eg.push(['so.com', 'q']);
        var dq = this.getDomainQuery(document.referrer);
        var keyword = null;
        var grep = null;
        var str = null;
        for (var el in eg) {
            var s = eg[el];
            var p = String(s).split(","); //字符分割
            if (dq[0].indexOf(p[0]) > 0) {
                eval("grep=/" + p[1] + "\=.*\&/i;");
                str = dq[1].match(grep);
                if (str) {
                    keyword = str.toString().split("=")[1].split("&")[0];
                    if (keyword) {
                        try {
                            return decodeURIComponent(keyword);
                        } catch (e) {
                            return keyword;
                        }
                    }
                }
            }
        }
        return '';
    }

    urlParam(url, name) {
        var reg = new RegExp("(^|&|\\?)" + name + "=([^&]*)(&|$)");
        var value = url.substr(1).match(reg);
        if (value != null)
            try {
                return decodeURIComponent(value[2]);
            } catch (e) {
                return value[2];
            }
        return null;
    }

    //chat api
    openChat(d) {
        var isOpen = this.options.onbeforeopen.call(this, d || {});
        if (isOpen === undefined || isOpen) {
            if (!this.options.openChat.call(this, d)) {
                //retrive required args before chatting
                /*if (!identity.readCookie("deptid")) {
                    this.selectDeptByScode(this.crm_params.s_code, (deptid) => {
                        deptid > 0 && identity.setCookie("deptid", deptid);
                    });
                }*/
                this.openChatDefault.call(this, this.extend(d || {}, { deptid: identity.readCookie("deptid") || 0 }));
            }
        }
    }
    createChatSdk() {
        if (this.conf.pvd_name == "bb" || this.conf.pvd_name == "bb-pre") {
            return new mebChatSdk(this);
        } else if (this.conv.pvd_name == "xd") {
            return new xiaoduoChatSdk(this);
        }
    }
    openChatDefault(p) {
        var params = this.getParamData();
        Object.assign(params,p||{});
        var sdk = 0;
        if (this.conf.pvd_name == "bb" || this.conf.pvd_name == "bb-pre" || this.conf.pvd_name == "bb-test") {
            sdk = new mebChatSdk(this);
            sdk.sdkOpenChat(this.conf.pvd_chat, params);
        } else if (this.conf.pvd_name == 'xd') {
            if (window.CVD_SDK) {
                sdk = new xiaoduoChatSdk(this);
                sdk.sdkOpenChat(this.conf.pvd_chat, params);
            }
            else{
                this.logger.infoAways("CVD_SDK_NOT_READY");
            }
        } else if (this.conf.pvd_name == 'kst') {
            var url = this.getChatUrl(p, true);
            if (this.options.is_mobile) {
                window.open(url);
            } else {
                var iWidth = 900;
                var iHeight = 800;
                var iTop = (window.screen.availHeight - 30 - iHeight) / 2;
                var iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
                var params = 'width=' + iWidth +
                    ',height=' + iHeight +
                    ',top=' + iTop +
                    ',left=' + iLeft +
                    ',channelmode=yes' //是否使用剧院模式显示窗口。默认为 no
                    +
                    ',directories=yes' //是否添加目录按钮。默认为 yes
                    +
                    ',fullscreen=no' //是否使用全屏模式显示浏览器
                    +
                    ',location=no' //是否显示地址字段。默认是 yes
                    +
                    ',menubar=no' //是否显示菜单栏。默认是 yes
                    +
                    ',resizable=no' //窗口是否可调节尺寸。默认是 yes
                    +
                    ',scrollbars=yes' //是否显示滚动条。默认是 yes
                    +
                    ',status=yes' //是否添加状态栏。默认是 yes
                    +
                    ',titlebar=yes' //默认是 yes
                    +
                    ',toolbar=no' //默认是 yes
                    ;
                window.open(url, "美尔贝咨询", params);
            }
        } else {
            Sentry && Sentry.captureMessage("CHAT_SDK_LOAD_FAILED")
            this.logger.infoAways("CHAT_SDK_LOAD_FAILED");
        }
        try {
            p.chatSdkLoad = this.chatSdkLoad;
            this.track_open_chat(p);
        } catch (e) {
            Sentry && Sentry.captureException(e);
        }
    }
    selectDeptByScode(scode, callback) {
        this.doRequest(this.path_dept, { scode: scode }, 'GET', "application/json", false, callback);
    }

    getChatUrl(d, withParams) {
        var data = this.getParamData(),
            chatUrl, i;
        this.extend(data, d || {}, true);
        if (this.conf.pvd_name == 'kst') {
            chatUrl = this.path_linkchat;
            for (i in data) {
                chatUrl = this.urlPrependParameter(chatUrl, i, this.encodeLen(data[i]));
            }
            return chatUrl;
        } else if (this.conf.pvd_name == 'xd') {
            chatUrl = this.conf.pvd_chat;
            if (withParams) {
                var thirdpart_params = [];
                for (i in data) {
                    thirdpart_params.push({ key: i, label: this.trimStr(data[i], 200) });
                }
                chatUrl = this.urlPrependParameter(chatUrl, 'thirdpart_params', encodeURIComponent(JSON.stringify(thirdpart_params)));
            }
            return chatUrl;
        } else if (this.conf.pvd_name == "bb" || this.conf.pvd_name == "bb-pre") {
            chatUrl = this.conf.pvd_chat;
            for (i in data) {
                chatUrl = this.urlPrependParameter(chatUrl, i, this.encodeLen(data[i]));
            }
            return chatUrl;
        } else {
            return this.path_linkchat;
        }
    }
    encodeLen(value) {
        var val = encodeURIComponent(this.trimStr(value, 200));
        return this.trimStr(val, 400);
    }
    willHide(p) {
        var b = this.options.onbeforehide.call(this, p);
        if (b == undefined) {
            return true;
        }
        return b;
    }
    willShow(p) {
        var w = p.where;
        var dlg_st = identity.readCookie('DIALOG_' + w + '_SHOW_TS');
        if (dlg_st == null)
            return true;
        var elapsed = (this.second() - dlg_st);
        var interval = this.getShowInterval(w);
        if (elapsed >= interval) {
            var b = this.options.onbeforeshow.call(this);
            if (b == undefined || b == null) {
                this.getShowInterval(w, 1);
                return true;
            }
            return b;
        }
        if (this.options.debug)
            this.logger.info('elapsed=' + elapsed + '/interval=' + interval + '/where=' + w);
        return false;
    }
    getShowInterval(part, shift) {
        var interval = 15;
        var si = this.conf.showInterval;
        if (this.conf.showMode == 0 && si['ALL'] && si['ALL'].length > 0) {
            interval = si['ALL'][0];
            shift && si['ALL'].shift();
        } else if (si[part] && si[part].length > 0) {
            interval = si[part][0];
            shift && si[part].shift();
        }
        return interval;
    }
    show(p) {
        p = this.extend(p || {}, this.showOption, false);
        p.where = p.where.toUpperCase();
        switch (p.by) {
            case 'load':
                if (this.willShow(p)) {
                    this.tryShow(p);
                }
                break;
            case 'user':
                this.tryShow(p);
                break;
            case 'timer':
                var that = this;
                var _show = function (d) {
                    return function () { that.show(d); };
                };
                var _hide = function (d) {
                    return function () { that.hide(d); };
                };
                if (this.willShow(p)) {
                    this.tryShow(p);
                    var hd = this.conf.hideDelay;
                    for (var w in hd) {
                        w == p.where && hd[w] && setTimeout(_hide(p), hd[w]);
                    }
                } else {
                    this.showTimer = setTimeout(_show(p), 1500);
                }
                break;
            default:
        }
    }
    tryShow(p) {
        try {
            this.options.show.call(this, p);
            p.by == 'load' && this.logpush("show", p);
        } catch (err) {
            Sentry && Sentry.captureException(err);
        }
    }
    tryHide(p) {
        try {

            this.options.hide.call(this, p);
            identity.setCookie('DIALOG_' + p.where + '_SHOW_TS', this.second());
            p.by == 'user' && this.logpush("hide", p);
        } catch (err) {
            Sentry && Sentry.captureException(err);
        }
    }
    findUIKey() {
        var e = window.event || this.getEvent(this.options.hide);
        if (e && e.type == 'click') {
            return this.themePack.targetType(e.currentTarget);
        }
    }
    hide(p) {
        p = this.extend(p || {}, this.showOption, false);
        var w = p.where.toUpperCase();
        this.conf.showMode == 1 && w == 'ALL' && (p.where = this.findUIKey());
        switch (p.by) {
            case 'user':
                if (this.willHide(p)) {
                    this.tryHide(p);

                    //show again after interval
                    p.by = 'timer';
                    clearTimeout(this.showTimer);
                    this.show(p);
                }
                break;
            default:
                debugger;
        }
    }
    second() {
        return parseInt(new Date().getTime() / 1000);
    }
    logpush(action, args) {
        try {
            var arg_value = {};
            var props = [];
            props.push('action', action);
            props.push('advertId', this.conf.advertId);

            var argstype = typeof args;
            if (argstype == 'string' || argstype == 'number') {
                if (args == 'default') args = {};
            } else if (argstype == 'object' && (JSON.stringify(args)) != "{}") {
                this.extend(arg_value, args, true);
            }
            arg_value["chatSdkLoad"] = this.chatSdkLoad;
            props.push('chatSdkName', this.conf.pvd_name);
            props.push('chatSdkLoad', this.chatSdkLoad);
            props.push('args', JSON.stringify(arg_value));
            props.push('date', this.date());
            props.push('time', this.dateTime());
            if (this.options.keyword) {
                try {
                    props.push('kw', decodeURIComponent(this.options.keyword));
                } catch (e) {
                    console && console.error && console.error(e);
                }
            }
            props.push('$title', document.title);
            props.push('mobile', this.options.is_mobile ? true : false);
            props.push('pvd_name', this.conf.pvd_name);
            props.push('theme_server', this.conf.theme_server);
            props.push('version', this.version);
            props.push('trackId', window.trackId);
            props.push('$url', this.crm_params.src_url);
            props.push('ref', this.crm_params.ref_url);
            props.push('scode', this.crm_params.s_code);
            props.push('eventSource', (args || {}).eventSource != undefined ? args.eventSource : null);
            if (action == "open") {
                this.track_open_chat(props);
            }
        } catch (e) {
            Sentry && Sentry.captureException(e);
        }
    }
    getEvent(caller) {
        return null;
        // if (document.all)
        //     return window.event; //For IE.
        // if (caller == null || typeof(caller) != "function")
        //     return null;
        // while (caller.caller != null) {
        //     caller = caller.caller;
        // }
        // return caller.arguments[0];
    }
    track_open_chat(args) {
        var d = args || {};
        var n = new Date().getTime();
        var duration = (n - this.PAGE_ENTRY_TS) / 1000;
        var event_props = {
            duration: duration,
            $title: document.title,
            $url: location.href,
            eventsource: d.eventSource || '',
            scode: meb_dialog.crm_params.s_code,
            themeid: meb_dialog.conf.advertId,
            wrap_version: meb_dialog.version,
            kw: meb_dialog.options.keyword,
            pvd_name: meb_dialog.conf.pvd_name,
            pvd_load: meb_dialog.chatSdkLoad ? true : false, //is js sdk is load
            is_mobile: meb_dialog.options.is_mobile ? true : false,
        }
        if (d.event_props) {
            Object.assign(event_props, d.event_props)
        }
        //神策
        meb_dialog.sensorsTrackEnabled &&
            window.sensors &&
            window.sensors.track("open_chat", event_props);
        // if (window.event)
        //     baiduIntergration.fireBaiduConversionEvent(window.event);

        meb_dialog.baiduAnalyticsEnabled &&
            window._hmt &&
            !window.isOpenChatCalled &&
            window._hmt.push(['_trackEvent', 'visitor', 'openChat', d.eventsource]);

        window.isOpenChatCalled = 1;
    }

    enableSensorsTrack(optioin, loadJs) {
        sensorsIntergration.enableSensorsTrack(optioin, loadJs);
        this.sensorsTrackEnabled = !!loadJs;
    }
    enableBaiduAnalytics(option, loadJs) {
        baiduIntergration.enableBaiduAnalytics(option, loadJs);
        this.baiduAnalyticsEnabled = !!loadJs;
    }
    enableSentry(option) {
        Sentry.setTag("hostname", location.hostname);
        Sentry.init({
            dsn: "https://e6cd19625c004f1a949a8e8a9b8cbc1a@dayu.meb.com:9000/2",
            integrations: [new Integrations.BrowserTracing()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: option.tracesSampleRate || 0.1
        });
        global.Sentry = Sentry;
    }
}
export default {
    init: function (option) {
        return new md(option);
    }
}
