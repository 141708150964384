var d = document,
    setCookie = function(name, value, time) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + time);
        return d.cookie = name + "=" + escape(value) + "; path=/" + ((time === null) ? "" : "; expires=" + exdate.toGMTString());
    },
    readCookie = function(name) {
        var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
        if ((arr = d.cookie.match(reg)) != null) {
            return (unescape(arr[2]))
        } else { return null }
    },
    uuid = function() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },
    setIdentity = function(id) {
        if (id && id.length == 36) {
            setCookie("AT_UID", id, 365);
            return id;
        }
        return 0;
    },
    setup = function(c) {
        var r = 0;
        for (var i = 0; i < c.length; i++) {
            r = setIdentity(r || readCookie(c[i]))
        }
        return r;
    }
export default {
    init: function(cookieNames) {
        var uid = setup(cookieNames);
        var is_first_visit = false;
        if (!uid) {
            uid = setIdentity(uuid());
            is_first_visit = true;
        }
        return {
            uid: uid,
            is_first_visit: is_first_visit
        }
    },
    setIdentity: setIdentity,
    getIdentity: readCookie("AT_UID"),
    setCookie: setCookie,
    readCookie: readCookie
}