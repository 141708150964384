import chatSdkBase from "./chatSdkBase";

function fnGetSdkOption(params,wrapper){
    if (wrapper.conf.pvd_channel_conf == null) {
        console.log("ERR_INVALID_CHANNEL_CONF");
        return;
    }
    var pvd_channel_conf = JSON.parse(wrapper.conf.pvd_channel_conf);
    return {
        json_params: params,
        key: pvd_channel_conf.key,
        cid: pvd_channel_conf.cid,
        tid: pvd_channel_conf.tid,
        cuid: window.trackId,
        scode: wrapper.crm_params.s_code,
        trigger: params.eventSource || '',
        setting:  {
            target: params.target || ''
        },
        refererUrl:params.refererurl,
        pageUrl:params.sourceurl
    }
}

function fnOpenChat(params, wrapper) {
    var sdkOption=fnGetSdkOption(params,wrapper);
    sdkOption.json_params = encodeURIComponent(JSON.stringify(sdkOption.json_params||{}));
    sdkOption.setting = encodeURIComponent(JSON.stringify(sdkOption.setting||{}));
    MEBCHATSDK.setOptions(sdkOption)
    MEBCHATSDK.open();
}
export default class mebChatSdk extends chatSdkBase {
    constructor(sdkWrapper) {
        super(sdkWrapper);
        this.jsonParams = null;
    }
    sdkOpenChatInNewTab(params) {
        params.target = 'newTab';
        fnOpenChat(params, this.wrapper);
    }
    sdkOpenChatOnPage(params) {
        params.target = '';
        fnOpenChat(params, this.wrapper);
    }
    sdkOpenChat(chat_url, params) {
        params.target = 'newTab';
        super.sdkOpenChat(chat_url,params);
    }
    sdkGetOpenUrl(chat_url, params) {
        var url = chat_url;
        var query = fnGetSdkOption(params,this.wrapper);
        query.json_params = JSON.stringify(query.json_params||{});
        query.setting = JSON.stringify(query.setting||{});
        
        for (let i in query) {
            url = this.wrapper.urlPrependParameter(url, i, encodeURIComponent(this.wrapper.trimStr(query[i], 500)));
        }
        return url;
    }
}