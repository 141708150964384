import dialog from "./src/dialog";
import uniid from "./src/uniid";

//load from other identities that pre-stored in cookies or setup a fresh one.
var u = uniid.init(["DeviceId", "AT_UID"]);
global.trackId = u.uid;
global.isFirstVisit = u.is_first_visit;
let option = global.meb_dialog_opt || {};
let md = dialog.init(option);
if (option.enableSensorsTrack) {
    let sensorsOption = option.sensorsTrackOption || {};
    if (!sensorsOption.env)
        sensorsOption.env = "prod";
    md.enableSensorsTrack(sensorsOption, true);
} else {
    md.enableSensorsTrack({}, false);
}
if (option.enableBaiduAnalytics) {
    let baiduOption = option.baiduAnalyticsOption || {};
    md.enableBaiduAnalytics(baiduOption, true);
} else {
    md.enableBaiduAnalytics({}, false)
}
if (option.enableSentry) {
    var sentryOption = option.sensorsOption || {};
    md.enableSentry(sentryOption);
}
global.meb_dialog = md;