export default
    class chatSdkBase {
    constructor(sdkWrapper) {
        this.wrapper = sdkWrapper;
        this.chatWindow = null;
    }
    sdkOpenChatOnPage(params) {
        throw new Error("function sdkOpenChatOnPage is not implemented.")
    }
    sdkOpenChatInNewTab(params) {
        throw new Error("function sdkOpenChatInNewTab is not implemented.")
    }
    sdkGetOpenUrl(chat_url, params) {
        throw new Error("function sdkGetOpenUrl is not implemented.")
    }
    sdkOpenChat(chat_url, params) {

        var url = this.sdkGetOpenUrl(chat_url, params);
        //0=SDK默认方式，1=SDK新标签页，2=window.open,3=location.href
        if (this.wrapper.conf.chatOpenType == undefined) {
            this.wrapper.conf.chatOpenType = 0;
        }
        if (this.chatWindow) {
            try {
                this.chatWindow.close();
            } catch (e) { } finally { this.chatWindow = null; }
        }
        switch (this.wrapper.conf.chatOpenType) {
            case 7:
                this.chatWindow = window.open(url, '_top');
                break;
            case 6:
                this.chatWindow = window.open(url, '_blank');
                break;
            case 5:
                top.location.href = url;
                break;
            case 4:
                parent.location.href = url;
                break;
            case 3:
                location.href = url;
                break;
            case 2:
                this.chatWindow = window.open(url, '_self');
                break;
            case 1:
                this.sdkOpenChatInNewTab(params);
                break;
            case 0:
            default:
                this.sdkOpenChatOnPage(params);
        }
    }
}